import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import styles from './blog.module.css'
import Page from "../components/page"
import Section from "../components/section"
import ArticlePreview from '../components/article-preview'
import { Grid, Cell } from '../components/grid'

import tr, { languageContentFilter } from '../i18n'

class BlogIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allContentfulBlogPost.edges')

    return (
      <Page location={this.props.location}>
      
        <Helmet title={siteTitle} />
        <div className={styles.hero}>
          Blog
        </div>
        <Section title={tr('Recent articles')} margin>
          <Grid>
            
            {
              posts.filter(languageContentFilter).map(({ node }) => 
                <Cell key={node.slug} xs={12} md={4}>
                  <ArticlePreview article={node} />
                </Cell>
              )
            }
            
          </Grid>
        </Section>
      
      </Page>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query BlogIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          node_locale
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
